<template>
  <div>
    <!-- 卡片视图区域 -->
    <el-card>
      <!-- 搜索与添加区域 -->
      <el-row :gutter="20">
        <el-col :span="8">
          <el-input
            placeholder="请输入内容"
            v-model="params.keyWord"
            clearable
            @clear="getUserList"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="getUserList"
            ></el-button>
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" @click="showAddClick()">添加账号</el-button>
        </el-col>
      </el-row>
      <!-- 账号列表区域 -->
      <el-table :data="records" border stripe>
        <el-table-column type="index"></el-table-column>
        <el-table-column label="账号ID" prop="userId"></el-table-column>
        <el-table-column label="昵称" prop="nickName"></el-table-column>
        <el-table-column label="姓名" prop="userName"></el-table-column>
        <el-table-column label="角色" prop="roleName"></el-table-column>
        <el-table-column label="创建人" prop="roleName"></el-table-column>
        <el-table-column label="状态">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.mg_state"
              @change="userStateChanged(scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="180px">
          <template slot-scope="scope">
            <!-- 修改按钮 -->
            <el-button
              type="primary"
              icon="el-icon-edit"
              size="mini"
              @click="showEditClick(scope.row)"
            ></el-button>
            <!-- 删除按钮 -->
            <el-button
              type="danger"
              icon="el-icon-delete"
              size="mini"
              @click="removeUserById(scope.row.userId)"
            ></el-button>
            <!-- 分配角色按钮 -->
            <el-tooltip
              effect="dark"
              content="分配角色"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="warning"
                icon="el-icon-setting"
                size="mini"
                @click="setRole(scope.row)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <!-- 添加账号的对话框 -->
    <el-dialog
      title="添加账号"
      :visible.sync="addDialogVisible"
      width="50%"
      @close="addDialogClosed"
    >
      <!-- 内容主体区域 -->
      <el-form :model="addForm" ref="addFormRef" label-width="100px">
        <el-form-item label="账号名">
          <el-input v-model="addForm.userName"></el-input>
        </el-form-item>
        <el-form-item label="昵称">
          <el-input v-model="addForm.nickName"></el-input>
        </el-form-item>

        <el-form-item label="密码">
          <el-input v-model="addForm.password"></el-input>
        </el-form-item>
        <el-form-item label="性别">
          <el-radio v-model="addForm.sex" label="0">男</el-radio>
          <el-radio v-model="addForm.sex" label="1">女</el-radio>
          <el-radio v-model="addForm.sex" label="2">其他</el-radio>
        </el-form-item>
        <el-form-item label="是否启用">
          <el-radio v-model="addForm.status" label="0">正常</el-radio>
          <el-radio v-model="addForm.status" label="1">停用</el-radio>
        </el-form-item>
        <el-form-item label="邮箱">
          <el-input v-model="addForm.email"></el-input>
        </el-form-item>
        <el-form-item label="手机">
          <el-input v-model="addForm.phonenumber"></el-input>
        </el-form-item>
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addUser">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 编辑账号的对话框 -->
    <el-dialog
      title="添加账号"
      :visible.sync="editDialogVisible"
      width="50%"
      @close="editDialogVisible = false"
    >
      <!-- 内容主体区域 -->
      <el-form :model="editForm" ref="addFormRef" label-width="100px">
        <el-form-item label="账号名" prop="username">
          <el-input v-model="editForm.userName"></el-input>
        </el-form-item>
        <el-form-item label="昵称" prop="nickName">
          <el-input v-model="editForm.nickName"></el-input>
        </el-form-item>
        <el-form-item label="是否启用" prop="是否启用">
          <el-radio v-model="editForm.status" label="0">正常</el-radio>
          <el-radio v-model="editForm.status" label="1">停用</el-radio>
        </el-form-item>
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateById">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 分配角色的对话框 -->
    <el-dialog
      title="分配角色"
      :visible.sync="isRoleDialogVisible"
      width="50%"
      @close="isRoleDialogVisible = false"
    >
      <div>
        <p>当前的账号：{{ userInfo.nickName }}</p>
        <p>当前的角色：{{ userInfo.roleName }}</p>
        <p>
          分配新角色：
          <el-select v-model="selectedRoleId" placeholder="请选择">
            <el-option
              v-for="item in rolesList"
              :key="item.roleId"
              :label="item.roleName"
              :value="item.roleId"
            >
            </el-option>
          </el-select>
        </p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isRoleDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveRoleInfo">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { checkEmail, checkMobile } from "../../../../utils/common/formCheck.js";
export default {
  name: "后台项目Index",
  data() {
    return {
      params: { pageNum: 1, pageSize: 5, keyWord: "" },
      records: [],
      addDialogVisible: false,
      editDialogVisible: false,
      addForm: {
        avatar: "",
        createUser: "",
        email: "",
        nickName: "",
        password: "",
        phonenumber: "",
        salt: "",
        sex: "0",
        status: "0",
        userName: "",
      },
      editForm: {},
      isRoleDialogVisible: false,
      // 添加表单的验证规则对象
      addFormRules: {
        username: [
          { required: true, message: "请输入账号名", trigger: "blur" },
          {
            min: 6,
            max: 15,
            message: "账号名的长度在3~10个字符之间",
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            min: 6,
            max: 15,
            message: "账号名的长度在6~15个字符之间",
            trigger: "blur",
          },
        ],
        email: [
          { required: true, message: "请输入邮箱", trigger: "blur" },
          { validator: checkEmail, trigger: "blur" },
        ],
        phonenumber: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { validator: checkMobile, trigger: "blur" },
        ],
      },

      addType: 1,
      userInfo: {},
      selectedRoleId: "",
      rolesList: [],
    };
  },

  mounted() {
    this.findList();
  },

  methods: {
    showEditClick(row) {
      this.editForm = row;
      this.editDialogVisible = true;
    },
    async showAddClick() {
      this.addDialogVisible = true;
    },

    async addUser() {
      const res = await this.$API.saveUser(this.addForm);
      if (res.status == 200) {
        this.addDialogVisible = false;
        this.findList();
      }
    },
    async updateById() {
      const res = await this.$API.updateById(this.addForm);
      if (res.status == 200) {
        this.editDialogVisible = false;
        this.findList();
      }
    },
    async findList() {
      const res = await this.$API.userList();
      if (res.status == 200) {
        this.records = res.data;
      }
    },
    async removeUserById(id) {
      const confirmResult = await this.$confirm(
        "此操作将永久删除该账号, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch(async (err) => err);
      if (confirmResult !== "confirm") {
        return this.$message.info("已取消删除");
      }

      const res = await this.$API.removeUser({ userId: id });
      if (res.status !== 200) return this.$message.error(res.msg);
      this.$message.success(res.data);
      this.findList();
    },

    //获取角色列表
    async getRoleList() {
      const { data } = await this.$API.getRoleList();
      this.roleList = data;
    },
    // 展示分配角色的对话框
    async setRole(userInfo) {
      this.userInfo = userInfo;
      // 在展示对话框之前，获取所有角色的列表
      const res = await this.$API.getRoleList();
      console.log(res.status);
      if (res.status == 200) {
        console.log(123);
        this.isRoleDialogVisible = true;
        this.rolesList = res.data;
      } else {
        return this.$message.error("获取角色列表失败！");
      }
    },

    // 点击按钮，分配角色
    async saveRoleInfo() {
      if (!this.selectedRoleId) {
        return this.$message.error("请选择要分配的角色！");
      }
      const params = {
        roleId: this.selectedRoleId,
        userId: this.userInfo.userId,
      };
      const res = await this.$API.saveUserRole(params);

      if (res.status !== 200) {
        return this.$message.error("更新角色失败！");
      }

      this.$message.success("更新角色成功！");
      this.findList();
      this.isRoleDialogVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
