export const checkEmail = (rule, value, cb) => {
  const regEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/;
  if (regEmail.test(value)) {
    return cb();
  }
  cb(new Error("请输入合法的邮箱"));
};
export const checkMobile = (rule, value, cb) => {
  const regMobile =
    /^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/;

  if (regMobile.test(value)) {
    return cb();
  }
  cb(new Error("请输入合法的手机号"));
};

export const EmailRule = [
  { required: true, message: "请输入的邮箱", trigger: "blur" },
  { validator: checkEmail },
];

export const MobileRule = [
  { required: true, message: "请输入手机号", trigger: "blur" },
  { validator: checkMobile },
];

// const rule = [
//     { required: true, message: "请输入用户名", trigger: "blur" },
//     {
//         min: 3,
//         max: 10,
//         message: "用户名的长度在3~10个字符之间",
//         trigger: "blur",
//     },
// ]

// function vxRule() {
//     const rule = { required: true, message: "请输入用户名", trigger: "blur" },
//     const validator = {
//         min: 3,
//         max: 10,
//         message: "用户名的长度在3~10个字符之间",
//         trigger: "blur",
//     }
// }

// export function vxRule(required = true, type, trigger = "blur", nullMsg = "该字段为必填项") {
//     const rule = { required: !!required, trigger }

//     let check = null
//     if (typeof type === "function") {
//         check = type
//     } else {
//         check = type ? rules[type + ""] : null
//     }

//     if (check) {//存在规则时添加规则
//         rule.validator = (r, v, c) => {
//             const { result, errMsg } = check(v)
//             if (required) {
//                 //必填项: null,undefined,"","  " 都算无输入内容
//                 return (v == null || (v + "").trim() === "") ? c(new Error(nullMsg)) : result ? c() : c(new Error(errMsg))
//             }
//             //选填项: null,undefined,"" 都算无输入内容，"  "会被校验
//             return (v == null || (v + "") === "" || result) ? c() : c(new Error(errMsg))
//         }
//     } else {
//         rule.message = nullMsg
//     }

//     return [rule]
// }
